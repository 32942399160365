<template>
  <v-card>
    <v-card-text>
      <v-data-table
        locale="fr-FR"
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :sort-by="'updated'"
        :sort-desc="true"
        :options.sync="options"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="3">
              <v-autocomplete
                v-model="projectId"
                :items="projects"
                label="Filtrer sur un projet"
                item-text="name"
                item-value="id"
                class="mx-3 select-project"
                dense
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Recherche par Nom ou Label/code"
                class="px-3"
                dense
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.name="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span class="text--primary" v-on="on" v-bind="attrs">{{
                item.name
              }}</span>
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <a target="_blank">
            <v-btn
              :to="`/rmr-analytics/event-types-edit/${item.id}`"
              class="mx-2"
              elevation="0"
              fab
              small
            >
              <v-icon color="primary">
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              elevation="0"
              fab
              small
              @click.native="
                dialogModel.dialog = !dialogModel.dialog;
                dialogModel.id = item.id;
              "
            >
              <v-icon color="secondary">
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </a>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialogModel.dialog" width="70vw">
      <del-confirmation
        :dialogOpen="dialogModel.dialog"
        :id="dialogModel.id"
        @openDialog="openDialog"
      ></del-confirmation>
    </v-dialog>
  </v-card>
</template>
<style scoped></style>
<script>
import debounce from "lodash/debounce";

import axios from "@axios";
import { mdiPencil, mdiTrashCan, mdiMagnify } from "@mdi/js";

import DelConfirmation from "@/components/rmr-analytics/modals/EventTypeDelConfirmation.vue";

import useQueryString from "@/hooks/useQueryString";

export default {
  name: "EventTypesTable",
  components: { DelConfirmation },
  data() {
    return {
      search: "",
      numberOfPages: 0,
      items: [],
      projectId: null,
      projects: [],
      loading: true,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      dialogModel: {
        dialog: false,
        id: -1,
      },
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Nom",
          align: "start",
          value: "name",
        },
        {
          text: "Label (code)",
          value: "label",
        },
        // {
        //   text: "Description",
        //   value: "description",
        // },
        {
          value: "actions",
        },
      ],
      icons: {
        mdiPencil,
        mdiTrashCan,
        mdiMagnify,
      },
    };
  },
  setup() {
    const { addQueryStringParam, getUrlValue } = useQueryString();

    return {
      addQueryStringParam,
      getUrlValue,
    };
  },
  async created() {
    const { data } = await axios.get("/rmra-projects/");
    this.projects = data.items;
    this.projectId = this.getUrlValue({
      param: "rmraEventTypeProjectId",
      // is_multiple: false,
      is_integer: true,
      // is_boolean: false,
    });
    this.search = this.getUrlValue({
      param: "rmraEventTypeSearch",
      // is_multiple: false,
      // is_integer: false,
      // is_boolean: false,
    });
  },
  watch: {
    options: {
      handler() {
        this.debouncedUpdateData();
      },
    },
    search() {
      this.options.page = 1;
      this.addQueryStringParam({
        param: "rmraEventTypeSearch",
        value: this.search ? this.search.trim() : "",
        // is_multiple: false,
        // is_integer: false,
        // is_boolean: false,
      });
      this.debouncedUpdateData();
    },
    projectId() {
      this.options.page = 1;
      this.addQueryStringParam({
        param: "rmraEventTypeProjectId",
        value: this.projectId,
        // is_multiple: false,
        is_integer: true,
        // is_boolean: false,
      });
      this.debouncedUpdateData();
    },
  },
  methods: {
    debouncedUpdateData: debounce(async function () {
      await this.updateData();
    }, 500),
    async updateData() {
      const queryParams = {
        projects: this.projectId,
        search: this.search,
      };
      this.loading = true;
      const { data } = await axios.get("/rmra-event-types/", {
        params: queryParams,
      });
      this.loading = false;
      this.items = data.items;
      this.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;
    },
    truncate(input) {
      return input.length > 5 ? `${input.substring(0, 30)}...` : input;
    },
    openDialog(value) {
      this.dialogModel.dialog = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-with-ga-id {
  max-width: 300px;
}
.above-table {
  width: 100%;
}
.search-by-site-id {
  width: 100px;
}
</style>
